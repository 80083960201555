export const ROUTES = {
  404: '*',
  home: '/',
  login: '/login',
  'login.redirect': '/login\\?redirect=:redirect',
  carousel: '/carousel/list',
  'carousel.view': '/carousel/view/:id',
  'carousel.create': '/carousel/create',
  'carousel.edit': '/carousel/edit/:id/details',
  categories: '/categories/list',
  'categories.view': '/categories/list/view/:id',
  'categories.edit': '/categories/edit/:id',
  connect: '/connect/list',
  'connect.create': '/connect/list/create',
  'connect.view': '/connect/list/view/:id',
  'connect.edit.details': '/connect/edit/:id/details',
  'connect.edit.copy': '/connect/edit/:id/copy',
  'connect.edit.customise': '/connect/edit/:id/customise',
  campaigns: '/campaigns/list',
  'campaigns.create': '/campaigns/create',
  'campaigns.edit.details':
    '/campaigns/edit/:countrySlug/:campaignSlug/details',
  'campaigns.edit.content':
    '/campaigns/edit/:countrySlug/:campaignSlug/content',
  discounts: '/discounts/list',
  'discounts.view': '/discounts/list/view/:id',
  'discounts.edit.details': '/discounts/edit/:countryCode/:id/details',
  'discounts.edit.additional_content_education':
    '/discounts/edit/:countryCode/:id/additional-content-education',
  'discounts.edit.redemption': '/discounts/edit/:countryCode/:id/redemption',
  'discounts.edit.visibility': '/discounts/edit/:countryCode/:id/visibility',
  'discounts.codes.edit': '/discounts/:id/codes/edit',
  'discounts.create': '/discounts/list/create',
  'discounts.edit.facets': '/discounts/edit/:countryCode/:id/facets',
  'discounts.edit.venues': '/discounts/edit/:countryCode/:id/venues',
  featured: '/discounts/featured/list',
  'featured.add': '/discounts/featured/add',
  'featured.edit': '/discounts/featured/edit/:id',
  test: '/test',
  testWithParams: '/test/:id',
  notifications: '/notifications/list',
  'notifications.create': '/notifications/create',
  'notifications.view': '/notifications/view/:id',
  'notifications.edit': '/notifications/edit/:id/details',
  promo_blocks: '/media/list',
  'promo_blocks.create': '/media/list/create',
  'promo_blocks.item.list': '/media/:blockId/item/list',
  'promo_blocks.item.create': '/media/:blockId/:promoBlockType/item/create',
  'promo_blocks.item.edit.details': '/media/:blockId/item/edit/:id/details',
  'promo_blocks.item.edit.customise': '/media/:blockId/item/edit/:id/customise',
  brand_list: '/brands/list',
  'brand_list.view': '/brands/view/:id',
  'brand_list.edit': '/brands/edit/:id/details',
  'brand_list.edit.premium': '/brands/edit/:id/premium',
  'brand_list.edit.facets': '/brands/edit/:id/facets',
  'brand_list.edit.venues': '/brands/edit/:id/venues',
  'brand_list.create': '/brands/create',
  collections: '/collections/list',
  'collections.edit.images': '/collections/edit/:id/images',
  'collections.edit.details': '/collections/edit/:id/details',
  'collections.view': '/collections/view/:id',
  'collections.discounts': '/collections/:id/discounts/list',
  'collections.discounts.create': '/collections/:id/discounts/list/create',
  'collections.create': '/collections/list/create',
  products: '/products/list',
  'products.edit': '/products/edit/:id',
  'products.view': '/products/view/:id',
  'products.create': '/products/create',
  'products.rail': '/products/rail',
  content: '/content/list',
  'content.edit': '/content/edit/:id',
  'content.view': '/content/view/:id',
  'content.create': '/content/list/create',
  'accounts.profiles': '/accounts/profiles',
  polls: '/polls/list',
  'polls.create': '/polls/create',
  'polls.edit': '/polls/edit/:id/details',
  reviews: '/reviews/list',
  'reviews.create': '/reviews/create',
  'reviews.edit': '/reviews/edit/:id/details',
  facets: '/facets/list',
  'facets.create.attribute': '/facets/create/attribute',
  'facets.create.value': '/facets/create/value',
  games: '/games/list',
  'games.create': '/games/create',
  'games.edit.details': '/games/edit/:id/details',
  'games.edit.content': '/games/edit/:id/content',
  'games.edit.content.question': '/games/edit/:id/content/question/:questionId',
  'instant.translator': '/instant-translator',
  translations: '/translations/projects/list',
  'translations.languages': '/translations/:project/languages',
  'translations.translations': '/translations/:project/translations',
  'translations.edit': '/translations/:project/:key/edit',
  'translations.create': '/translations/:project/create'
}
