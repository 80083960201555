/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRouter } from 'next/router'

import { routerCanGoBack } from './helpers'

// Temporary hooks to migrate from react-router-dom -> NextJS in multiple MRs
export const useParams = () => {
  const router = useRouter()
  return router.query
}

export const useHistory = () => {
  const router = useRouter()

  const { back, reload } = router

  router.back = () => {
    if (router.query?.returnTo) {
      router.push(router.query?.returnTo)
    } else if (routerCanGoBack()) {
      back()
    } else {
      router.push('/')
    }
  }

  router.reload = () => {
    reload()
  }

  return router
}
